<template>
    <div style="position:relative">
        <div v-if="isIE">
            <v-overlay z-index="99999" :opacity="0.6" style="font-size: 26px; text-align: center;">
                Internet Explorer desteklenmemektedir.
                <br />
                Modern bir Tarayici ile <strong>Tedarikci Portali</strong>ni kullanabilirsiniz
                <br />
                <br />
                <hr style="color:white; background-color:white;" />
                <br />
                Internet Explorer is not supported.
                <br />
                You can use <strong>Supplier Portal</strong> with a modern browser
            </v-overlay>
        </div>
        <div>
            <v-app style="background-color: #f5f5f5">
                <nav class="mt-6">
                    <v-app-bar flat
                               app
                               tile
                               color="primary"
                               hide-on-scroll
                               scroll-threshold="100"
                               elevation="1">
                        <v-app-bar-nav-icon @click="drawer = !drawer"
                                            class="white--text"></v-app-bar-nav-icon>
                        <v-col cols="auto" class="white--text display-1 font-weight-bold">
                            KPORTAL
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto">
                            <span class="font-weight-bold white--text caption text-decoration-underline" style="cursor:pointer" @click="ChangeLanguage()">{{ $t("AL_ChangeLanguage") }}</span>
                        </v-col>
                        <v-divider vertical inset></v-divider>
                        <v-col cols="auto" class="white--text title font-weight-bold">{{ $t("TH_SupplierPortal") }}</v-col>
                    </v-app-bar>
                    <v-navigation-drawer v-model="drawer" app>
                        <v-layout column align-center>
                            <v-flex class="mt-5 text-center">
                                <v-avatar size="100" color="white--text secondary lighten-2 display-1 font-weight-bold" style="line-height: 0 !important"
                                          class="mb-4">
                                    <v-icon style="font-size: 64px; color: white;">mdi-account-circle</v-icon>
                                </v-avatar>
                                <p class="title my-0">
                                    {{ $t("IM_Welcome") }}
                                </p>
                                <span class="text-caption font-weight-light">{{ $t("IM_PleaseSignIn") }}</span>
                            </v-flex>
                        </v-layout>
                        <v-list>
                            <v-list-item-group class="v-item-group--black" active-class="primary lighten-1 white--text" v-model="menuItemsSelected">
                                <v-list-item v-for="(item, i) in items" :key="i" :to="{name: item.name}" class="mb-1" active>
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-medium" style="white-space: normal;">
                                            {{$t(item.text)}}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                        <v-icon>{{item.icon}}</v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-navigation-drawer>
                    <showdialog :message="messages"></showdialog>
                    <terms :message="messages"></terms>
                    <router-view />
                </nav>
            </v-app>
        </div>
    </div>
</template>
<script>
    import Vue from "vue"
    import Vuex from "vuex"

    import { mapGetters } from "vuex";
    import dynoform from "@/components/DynoForm.vue";
    import grid from "@/components/grid.vue";
    import leftmenu from "@/components/leftmenu.vue";
    import kview from "@/components/view.vue";
    import axios from "axios";
    import _ from "lodash";
    import 'material-design-icons-iconfont/dist/material-design-icons.css';
    import { FormMixIn } from "@/MixIns/FormMixins";
    import store from '@/store';
    import Utilities from '@/Utilities.js'

    import showDialog from '@/components/ShowDialog.vue';
    import terms from '@/components/AgreementDialog.vue';

    import moment from "moment";

    export default {
        mixins: [FormMixIn],
        components: {
            dynoform,
            grid,
            kview
        },
        props: {
            form: String,
            formid: Number,
            viewname: String,
            message: Object
        },
        data: function ()
        {
            return {
                drawer: true,
                valid: false,
                lazy: true,
                username: '',
                viewName: '',
                //form: '',
                menuItemsSelected: null,
                items: [],
                validateError: '',
                items: [],
                timeNow: '',
                fullPage: true,
                messages: { messageType: '', messageBody: '', infoBody: '', viewType: '' },
                isIE: false
            };
        },
        computed:
        {

        },
        methods: {
            UpdateTime()
            {
                var timeNow = moment().format("DD/MM/YYYY HH:mm:ss");
                this.timeNow = timeNow;
            },
            ChangeLanguage()
            {
                var lang = Utilities.GetCurrentLanguage();

                if (lang == 'tr')
                {
                    lang = 'en';
                }
                else
                {
                    lang = 'tr';
                }

                this.$root.loadLanguageAsync(lang);
            },
            TimeLoop()
            {
                setTimeout(() =>
                {
                    this.TimeLoop();
                    Utilities.log("Keep Alive");
                    this.$store.dispatch("KeepAlive")
                }, 30000);
                this.UpdateTime();
            }
        },
        mounted()
        {
            Utilities.log("Form MOUNTED");
            this.TimeLoop();

            this.isIE = !!document.documentMode;
        },

        created: function ()
        {
            this.items = [
                { text: 'AL_Login', icon: 'mdi-login-variant', name: 'login' },
                { text: 'RL_CreateAccount', icon: 'mdi-account-plus-outline', name: 'register' },
                { text: 'AL_ApplicationAkbankSupplier', icon: 'mdi-account-edit-outline', name: 'kayittalebi' },
            ];
        },


    };</script>

<style scoped>
    .error--border {
        border: 2px solid !important;
    }

    .reload-captcha {
        max-height: 70px !important;
        min-height: 70px !important;
        max-width: 70px !important;
        min-width: 70px !important;
        color: #02a5a5 !important;
    }

        .reload-captcha:hover {
            color: white !important;
        }

    a:hover {
        text-decoration: none;
    }

    .rowactive {
        background-color: #FFEB3B;
    }
    .v-item-group--black .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
        color: rgb(0,0,0) !important;
    }
</style>